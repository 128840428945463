/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js
 * - /npm/swiper@11.1.12/swiper-bundle.min.js
 * - /npm/rellax@1.12.1/rellax.min.js
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/glightbox@3.3.0/dist/js/glightbox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
